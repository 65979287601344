<template lang="pug">
  article.collection-loan-request.pb-140
    main-el.pt-30.px-20(v-if="doc")
      animate-push-on-panel
        h1.text-24.md_text-30.xl_text-34.md_px-20.mb-40.md_mb-100 {{ doc.title }}
        section.md_w-7x12.md_px-20.mx-auto.textbody.text-18(v-if="doc.text", v-html="doc.text")
</template>

<script>
export default {
  name: 'CollectionLoansPage',
  computed: {
    docs () {
      return {
        borrow: {
          title: 'Borrow',
          text: this.$store.state.singles.collectionIndex?.loanRequestInfo
        },
        guidelines: {
          title: 'Loan Guidelines',
          text: this.$store.state.singles.collectionIndex?.loanGuidelines
        }
      }
    },
    doc () {
      return this.docs[this.$route.params.page]
    }
  },
  created () {
    this.$store.dispatch('getSingle', 'collectionIndex')
  },
  metaInfo () {
    const title = this.doc.title
    return {
      title,
      meta: this.$store.getters.meta({ title })
    }
  }
}
</script>

<style>
</style>
